import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import * as s from './PartnersIconsBlock.module.css';

const PartnersIconsBlock = ({ block }) => {

  const {
    wp: {
      acfOptionsPartnerIcons: {
        partnerIconsSettings: {
          partnerIconsBlock
        }
      }
    }
  } = useStaticQuery(graphql`
    query MyOptionsPartnersIconsQuery {
      wp {
        acfOptionsPartnerIcons {
          partnerIconsSettings {
            partnerIconsBlock {
              title
              partners {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);


  let data = {};
  if (true == block?.useLocal) {
    data = block;
  } else {
    data = partnerIconsBlock;
  }

  return (
    <>
      <section className={s.block} id={block?.blockId}>
        <div className="container">
          <div className="d-flex align-items-center justify-content-center flex-column flex-lg-row" style={{ gap: '30px' }}>
            {!!data.title && <h2 className={s.sectionTitle}>{data.title}</h2>}
            <div className={`${s.partnersLogosWrapper} `}>
              {data.partners.map((item, index) => (
                <div className={s.singleLogo} key={index}>
                  <PartnerIcon logo={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const PartnerIcon = ({ logo }) => {
  const image = {
    data: logo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: logo?.altText || ``,
  };
  return (
    <>
      {image.data &&
        <GatsbyImage
          loading="eager"
          placeholder="dominantColor"
          image={image.data}
          alt={image.alt}
          className={`mw-100 mh-100`} />
      }
    </>
  )
}

export default PartnersIconsBlock