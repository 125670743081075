import { graphql } from "gatsby";
import parse from "html-react-parser";
import React, { useEffect } from "react";

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../assets/css/@wordpress/block-library/build-style/style.css";
import "../assets/css/@wordpress/block-library/build-style/theme.css";

import PpcAbout from "../components/ppc/PpcAbout/PpcAbout";
import PpcBannerBlock from "../components/ppc/PpcBannerBlock/PpcBannerBlock";
import PpcHero from "../components/ppc/PpcHero/PpcHero";
import PpcVideos from "../components/ppc/PpcVideos/PpcVideos";
import PpcWhyPartner from "../components/ppc/PpcWhyPartner/PpcWhyPartner";
import schemaBuilder from "../utils/schemaBuilder";
import GlobalFormBlock from "./../components/GlobalFormBlock/GlobalFormBlock";
import HomeTestimonialsBlock2 from './../components/HomeTestimonialsBlock2/HomeTestimonialsBlock2';
import PartnersIconsBlock from "./../components/PartnersIconsBlock/PartnersIconsBlock";
import FooterPPC from './../components/ppc/footer/footer';
import HeaderPPC from './../components/ppc/header/header';
import Seo from "./../components/seo";

const PaidAdTemplate = ({ data: { page, author, site }, location }) => {

  const [display, setDisplay] = React.useState(false);
  useEffect(() => {
    setTimeout(function () {
      setDisplay(true);
    }, 200);
  }, []);

  // const vacancyRef = React.useRef(null);
  const [vacancyRef, setVacancyRef] = React.useState(null);
  const [quizRef, setQuizRef] = React.useState(null);
  // scroll to ref example function
  const scrollToRef = (ref) => {
    ref?.scrollIntoView({ behavior: "smooth" });
  };

  const schema = schemaBuilder({ page: page, author: author }, 'page', site);

  return (
    <div>
      <HeaderPPC />
      <main>
        <Seo
          title={page.title}
          description={""}
          pageSeo={page}
          location={location}
          schema={schema}
          customMeta={[
            {
              name: `robots`,
              content: `noindex,follow`,
            }
          ]}
        />
        {
          !!page?.ppc_landing_content?.ppcLandingContent &&
          page?.ppc_landing_content?.ppcLandingContent.map((block, index) => {
            switch (block.__typename) {
              case 'WpPaidAd_PpcLandingContent_PpcLandingContent_PpcHero':
                return <PpcHero block={block} page={page.title} key={`block-${index}`} />
                break;
              case 'WpPaidAd_PpcLandingContent_PpcLandingContent_TestimonialsBlock':
                return <HomeTestimonialsBlock2 block={block} display={display} key={`block-${index}`} />
                break;
              case 'WpPaidAd_PpcLandingContent_PpcLandingContent_PpcBannerBlock':
                return <PpcBannerBlock block={block} key={`block-${index}`} />
                break;
              case 'WpPaidAd_PpcLandingContent_PpcLandingContent_PartnersIconsBlock':
                return <PartnersIconsBlock block={block} key={`block-${index}`} />
                break;
              case 'WpPaidAd_PpcLandingContent_PpcLandingContent_GlobalFormBlock':
                return <GlobalFormBlock block={block} key={`block-${index}`} pageTitle={page.title} />
                break;
              case 'WpPaidAd_PpcLandingContent_PpcLandingContent_PpcWhyPartner':
                return <PpcWhyPartner block={block} key={`block-${index}`} />
                break;
              case 'WpPaidAd_PpcLandingContent_PpcLandingContent_PpcAbout':
                return <PpcAbout block={block} key={`block-${index}`} />
                break;
              case 'WpPaidAd_PpcLandingContent_PpcLandingContent_PpcVideos':
                return <PpcVideos block={block} key={`block-${index}`} />
                break;

              default:
                return <section className="container d-none" style={{ 'wordBreak': 'break-word' }} key={`block-${index}`}>
                  {block.__typename}
                  {/* {JSON.stringify(block, null ,2)} */}
                  <hr />
                </section>
            }
          })
        }
        <section className="container">
          {!!page.content && (
            <section>{parse(page.content)}</section>
          )}
        </section>
      </main>
      <FooterPPC />
    </div>
  )
}

export default PaidAdTemplate

export const pageQuery = graphql`
  query PaidAdById(
    $id: String!
    $authorId: String!
  ) {
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
    page: wpPaidAd(id: { eq: $id }) {
      id
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
      ppc_landing_content {
        ppcLandingContent {
          ... on WpPaidAd_PpcLandingContent_PpcLandingContent_PpcHero {
            __typename
            blockId
            title
            subtitle
            form {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
              youtubeVideoId
              title
              subtitle
              infoItems {
                text
              }
              zapierWebhookUrl
            }
          }
          ... on WpPaidAd_PpcLandingContent_PpcLandingContent_PartnersIconsBlock {
            __typename
            useLocal
            blockId
            title
            partners {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
          ... on WpPaidAd_PpcLandingContent_PpcLandingContent_PpcBannerBlock {
            __typename
            blockId
            bgColor
            textColor
            title
            description
            link {
              target
              title
              url
            }
          }
          ... on WpPaidAd_PpcLandingContent_PpcLandingContent_TestimonialsBlock {
            __typename
            blockId
            fieldGroupName
            title
            testimonials {
              fieldGroupName
              testimonial {
                __typename
                ... on WpTestimonial {
                  acf_testimonial {
                    position
                    stars
                  }
                  content
                  title
                  featuredImage {
                    node {
                      __typename
                      ... on WpMediaItem {
                        mediaItemUrl
                        localFile {
                          childImageSharp {
                            gatsbyImageData(quality: 100)
                          }
                        }
                        altText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPaidAd_PpcLandingContent_PpcLandingContent_GlobalFormBlock {
            __typename
            blockId
            fieldGroupName
            title
            subtitle
            image {
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
              altText
            }
          }
          ... on WpPaidAd_PpcLandingContent_PpcLandingContent_PpcWhyPartner {
            __typename
            blockId
            title
            subtitle
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            imageMobile {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            items {
              icon {
                altText
                localFile {
                  publicURL
                }
              }
              title
              description
            }
          }
          ... on WpPaidAd_PpcLandingContent_PpcLandingContent_PpcAbout {
            __typename
            blockId
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            title
            description
            stats {
              value
              label
            }
          }
          ... on WpPaidAd_PpcLandingContent_PpcLandingContent_PpcVideos {
            __typename
            blockId
            title
            videos {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
              youtubeVideoId
              title
              subtitle
            }
          }
        }
      }
    }
    author: wpUser(id: {eq: $authorId}) {
      avatar {
        size
        url
      }
      users {
        avatar {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
      name
      description
      email
      slug
      uri
      seo {
        metaDesc
        social {
          facebook
          linkedIn
          twitter
          wikipedia
        }
      }
    }
  }
`