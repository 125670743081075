import { GatsbyImage } from 'gatsby-plugin-image';
import parse from "html-react-parser";
import React, { useState } from 'react';
import * as s from './PpcVideos.module.css';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import YoutubeVideo from '../../youtubeVideo/youtubeVideo';

const PpcVideos = ({ block }) => {

  const bp = useBreakpoint();

  const settingsCommon = {
    dots: false,
    arrows: false,
    // infinite: false,
    // slidesToShow: 3.8,
    infinite: false,
    slidesToShow: 3,
    centerMode: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 991.9,
        settings: {
          slidesToShow: 2,
          variableWidth: true,
        },
      },
      {
        breakpoint: 767.9,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 575.9,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
        },
      }
    ],
  }


  return (
    <>
      <section className={s.block}>
        <div className='container'>
          <div className='row'>
            <div className="col-12">
              {!!block.title && <h2 className={`${s.title}`}>{parse(block.title)}</h2>}
            </div>
          </div>
        </div>
        {true == bp?.lg && <div className='container'>
          <div className='row'>
            <Slider {...settingsCommon}>
              {
                block?.videos?.map((item, i) => {
                  return (
                    <div key={`slider-item-${i}`} className={`px-2 ${s.videoSlideItem}`}>
                      <SliderVideo data={item} wrapperClasses={s.youtubeVideoPlaceholder1} />
                    </div>
                  )
                })
              }
            </Slider>
          </div>
        </div>}
        {false == bp?.lg &&
          <Slider {...settingsCommon} className='ps-container'>
            {
              block?.videos?.map((item, i) => {
                return (
                  <div key={`slider-item-${i}`} className={`pe-2 ${s.videoSlideItem}`}>
                    <SliderVideo data={item} wrapperClasses={s.youtubeVideoPlaceholder1} />
                  </div>
                )
              })
            }
          </Slider>
        }
      </section>
    </>
  )
}
export default PpcVideos

const SliderVideo = ({ data, sectionClasses, wrapperClasses, autoplay }) => {
  if (undefined == autoplay) {
    autoplay = 1;
  }
  const image = {
    data: data?.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data?.image?.altText || ``,
  };
  return (
    <>
      <YoutubeVideo autoplay={autoplay} imageUrl={image} placeholderStyles={{ width: '100%' }} videoId={data.youtubeVideoId} sectionClasses={sectionClasses} wrapperClasses={wrapperClasses} />
      {!!data.title && <div className={s.videoTitle}>{parse(data.title)}</div>}
      {!!data.subtitle && <div className={s.videoSubtitle}>{parse(data.subtitle)}</div>}
    </>
  )
}