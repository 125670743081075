import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./PpcWhyPartner.module.css";

const PpcWhyPartner = ({ block }) => {
  const bp = useBreakpoint();

  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };

  const imageMobile = {
    data: block.imageMobile?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.imageMobile?.altText || ``,
  };

  return (
    <>
      <section className={`${s.block}`}>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-8 col-xl-7 col-xxl-6 mb-4 pb-2'>
              <h2 className={`${s.title} px-2 px-md-0`}>{parse(block.title)}</h2>
              {!!block.subtitle && <div className={`${s.subtitle} px-2 px-md-0`}>{parse(block.subtitle)}</div>}
            </div>
            <div className='col-12'></div>
            {(bp.lg && !!image?.data) && <div className='col-12 col-lg-5 col-xxl-6'>
              <GatsbyImage
                className={`mw-100`}
                loading="eager"
                placeholder="transparent"
                image={image.data}
                alt={image.alt} />

            </div>}
            <div className='col-12 col-lg-7 col-xxl-5'>
              {block?.items?.length > 0 && <div className={s.valueItemsWrapper}>
                {block?.items?.map((item, i) => {
                  return <SingleValue value={item} key={i} />
                })}
              </div>}
            </div>

          </div>
        </div>
        {(false == bp.lg && !!imageMobile?.data) && <div className='d-flex justify-content-center'>
          <GatsbyImage
            className={`mw-100`}
            loading="eager"
            placeholder="transparent"
            image={imageMobile.data}
            alt={imageMobile.alt} />
        </div>}
      </section >
    </>
  );
};

const SingleValue = ({ value }) => {
  const bp = useBreakpoint();
  const icon = {
    src: value.icon?.localFile?.publicURL,
    alt: value.icon?.altText || ``,
  };

  return <div className={s.valueItem}>
    <div className={s.valueIcon}>
      {!!icon?.src &&
        <img src={icon?.src} alt={icon.alt} className={`mw-100`} />}
    </div>
    <div>
      {!!value.title && <div className={s.valueTitle}>{parse(value.title)}</div>}
      {(bp.lg && !!value.description) && <div className={s.valueDescription}>{parse(value.description)}</div>}
    </div>
  </div>
}

export default PpcWhyPartner;
