// extracted by mini-css-extract-plugin
export var bg = "PpcHero-module--bg--97f5f";
export var block = "PpcHero-module--block--2d851";
export var dateTime = "PpcHero-module--date-time--5d3bb";
export var form = "PpcHero-module--form--5bb3c";
export var formSubtitle = "PpcHero-module--formSubtitle--4602b";
export var formTitle = "PpcHero-module--formTitle--bcf84";
export var infoText = "PpcHero-module--infoText--16035";
export var infoTextContent = "PpcHero-module--infoTextContent--9a8ea";
export var infoTextMarker = "PpcHero-module--infoTextMarker--b3a47";
export var row = "PpcHero-module--row--608fa";
export var submitButton = "PpcHero-module--submitButton--59bdf";
export var subtitle = "PpcHero-module--subtitle--bc346";
export var thankYouText = "PpcHero-module--thankYouText--86bb2";
export var title = "PpcHero-module--title--3a99e";
export var topBlockButton = "PpcHero-module--top-block-button--e12ac";
export var youtubeVideo = "PpcHero-module--youtubeVideo--326a3";
export var youtubeVideoSection = "PpcHero-module--youtubeVideoSection--6793a";