

import { Link } from "gatsby";
import React from "react";
import parse from "html-react-parser";
// import logo from "../../assets/images/logo.webp";
import * as s from "./PpcBannerBlock.module.css";

const PpcBannerBlock = ({ block }) => {

  const styles = {
    '--bg-color': !!block?.bgColor ? block?.bgColor : '#063B6D',
    '--text-color': !!block?.textColor ? block?.textColor : '#ffffff',
  }

  return (
    <section className={`w-100 ${s.block}`} id={block?.blockId} style={styles}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {!!block?.title && <h2 className={s.title}>{parse(block?.title)}</h2>}
          </div>
          <div className="col-12">
            {!!block?.description && <div className={s.description}>{parse(block?.description)}</div>}
          </div>
          <div className="col-12 d-flex justify-content-center">
            {!!block?.link?.url && <Link to={block?.link?.url} target={block?.link?.target} className={s.button}>{block?.link?.title}</Link>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PpcBannerBlock;
