import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import * as s from "./footer.module.css";

const FooterPPC = () => {

  const footerRef = useRef();
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    footerRef,
    {},
    { disconnectOnLeave: false },
    {}
  );

  const {
    policiesMenu: { policiesMenuItems },
  } = useStaticQuery(graphql`
    query PartnerWithUsFooterMenuQuery {
      policiesMenu: wpMenu(locations: {eq: POLICIES_MENU}) {
        policiesMenuItems: menuItems {
          nodes {
            id
            uri
            url
            title
            target
            label
            linkRelationship
            cssClasses
            parentId
          }
        }
      }
    }
  `)

  const flatListToHierarchical = (
    data = [],
    { idKey = "id", parentKey = "parentId", childrenKey = "items" } = {}
  ) => {
    const tree = []
    const childrenOf = {}
    data.forEach(item => {
      const newItem = { ...item }
      const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
      childrenOf[id] = childrenOf[id] || []
      newItem[childrenKey] = childrenOf[id]
      parentId
        ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
        : tree.push(newItem)
    })
    return tree
  }

  let policiesMenuItemsTree = flatListToHierarchical(policiesMenuItems.nodes);

  return (
    <>
      <footer className="d-flex" ref={footerRef}>
        {enterCount > 0 &&
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className={s.footerCopyright + " text-center"}>
                  Copyright © {new Date().getFullYear()}. Azarian Growth Agency. All rights reserved.
                </div>
                <div className={`${s.footerPolicies} d-flex justify-content-center align-items-center`} style={{ gap: '8px' }}>
                  {policiesMenuItemsTree.map((mi, i) => {
                    return <Link to={mi.uri} key={mi.id}>{mi.label}</Link>
                  })}
                </div>
              </div>
            </div>
          </div>
        }
      </footer>
    </>
  );
};

export default FooterPPC;
