import { Link } from "gatsby";
import React from "react";
// import logo from "../../assets/images/logo.webp";
import logo from "../../../assets/images/header-logo-desktop.png";
import logoMobile from "../../../assets/images/header-logo-mobile.png";
import * as s from "./header.module.css";

const HeaderPPC = () => {

  return (
    <div className={`w-100 ${s.headerWrapper}`}>
      <header className={`${s.header} position-relative`}>
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className={s.logoWrapper}>
              <img
                alt="logo"
                width={254}
                height={50}
                src={logo}
                loading="eager"
                className="d-none d-md-block"
              />
              <img
                alt="logo-mobile"
                width={170}
                height={33}
                src={logo}
                loading="eager"
                className="d-md-none"
              />
            </div>
            {/* <a href="tel:12124567890" className={s.contact}> Contact Us:<br className="d-md-none" /> +1-212-456-7890</a> */}
          </div>
        </div>
      </header>
    </div>
  );
};

export default HeaderPPC;
