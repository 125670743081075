
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./PpcAbout.module.css";

const PpcAbout = ({ block }) => {
  const bp = useBreakpoint();

  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };

  return (
    <>
      <section className={`${s.block}`} >
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div className='col-12 col-lg-5 col-xxl-5'>
              {bp.lg && !!image?.data && <GatsbyImage
                className={`mw-100`}
                loading="eager"
                placeholder="transparent"
                image={image.data}
                alt={image.alt} />}
            </div>
            <div className='col-12 col-lg-7 col-xxl-6 offset-xxl-1'>
              <div className='px-2 px-md-0'>
                <h1 className={`${s.title}`}>{parse(block.title)}</h1>
                {!!block.description && <div className={`${s.description}`}>{parse(block.description)}</div>}
                {!!block.boldBottomText && <div className={`${s.boldBottomText}`}>{parse(block.boldBottomText)}</div>}
                <div className={s.statsWrapper}>
                  {block?.stats?.map((stat, i) => {
                    return <div key={i} className={s.stat}>
                      {!!stat.value && <div className={s.statValue}>{parse(stat.value)}</div>}
                      {!!stat.label && <div className={s.statTitle}>{parse(stat.label)}</div>}
                    </div>
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default PpcAbout;
