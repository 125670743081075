import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useState } from "react";

import * as s from "./PpcHero.module.css";

import makeZapierRequest from '../../../utils/zapierRequests';
import zapiers from '../../../utils/zapiers';
import FieldText from "../../FormsComponents/fields/fieldText";
import YoutubeVideo from "../../youtubeVideo/youtubeVideo";
import FieldDropdown from './../../FormsComponents/fields/fieldDropdown';

const PpcHero = ({ block, page }) => {
  const bp = useBreakpoint();

  const [messageSent, setMessageSent] = useState(false);
  const [showRecording, setShowRecording] = useState(false);

  const image = {
    data: block?.form.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.form.image?.altText || ``,
  };

  const styles = {
    '--title-color': !!block?.titleColor ? block?.titleColor : '#ffffff',
  };

  let placeholderImageUrl = "";
  if (!!block?.form?.youtubeVideoId) {
    if (!!image?.data) {
      placeholderImageUrl = image
    } else {
      placeholderImageUrl = `https://img.youtube.com/vi/${block?.form?.youtubeVideoId}/maxresdefault.jpg`;
    }
  }

  const handleFormSubmit = (payload) => {
    makeZapierRequest({ ...payload }, block?.form?.zapierWebhookUrl);
    setMessageSent(true);
  }

  return (
    <>
      <section className={`${s.block} d-flex`} id="hero" style={styles}>
        <StaticImage
          src={'./PPC_Hero_bg.png'}
          className={`${s.bg}`}
          alt=""
          quality={100}
        />
        <div className="container position-relative">
          <div className={`${s.row} row justify-content-center`}>
            <div className="col-12">
              <div className="d-flex flex-column align-items-center">
                {!!block.title && <h1 className={`${s.title}`}>{parse(block.title)}</h1>}
                {!!block.subtitle &&
                  <div className={`${s.subtitle}`}>{parse(block.subtitle)}</div>
                }
              </div>
            </div>

            <div className='col-xxl-10'>
              <div className={`${s.form} mx-xxl-4`}>
                <div className="row">
                  <div className="col-lg-5">
                    {!!block?.form?.youtubeVideoId &&
                      <YoutubeVideo
                        imageUrl={placeholderImageUrl}
                        videoId={block?.form?.youtubeVideoId}
                        sectionClasses={s.youtubeVideoSection}
                        placeholderStyles={{ width: '100%' }}
                        wrapperClasses={s.youtubeVideo} />
                    }
                    {(!block?.form?.youtubeVideoId && !!image.data) &&
                      <GatsbyImage
                        loading="eager"
                        placeholder="dominantColor"
                        image={image.data}
                        alt={image.alt}
                        className={`mw-100`} />
                    }
                  </div>
                  <div className="col-lg-7">
                    <div className=' px-3 px-lg-0'>
                      {!!block?.form?.title && <div className={s.formTitle}>{parse(block?.form?.title)}</div>}
                      {!!block?.form?.subtitle && <div className={s.formSubtitle}>{parse(block?.form?.subtitle)}</div>}
                      {block?.form?.infoItems?.length > 0 && <div className='mt-4 pb-2 col-xxl-11 d-none d-lg-block'>
                        {block?.form?.infoItems?.map((item, i) => {
                          return <div key={i} className={s.infoText}>
                            <div className={s.infoTextMarker}>→</div>
                            <div className={s.infoTextContent}>{parse(item?.text ?? '')}</div>
                          </div>
                        })}
                      </div>}
                      <div className='mt-4 pt-2 col-xxl-9 px-0'>
                        <Form block={block?.form} page={page} onSubmit={handleFormSubmit} />
                      </div>
                      {!!messageSent && <div className={s.thankYouText}>Thank you. Your request has been sent.</div>}
                      {block?.form?.infoItems?.length > 0 && <div className='mt-4 pt-2 d-lg-none'>
                        {block?.form?.infoItems?.map((item, i) => {
                          return <div key={i} className={s.infoText}>
                            <div className={s.infoTextMarker}>→</div>
                            <div className={s.infoTextContent}>{parse(item?.text ?? '')}</div>
                          </div>
                        })}
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


const Form = ({ block, page, onSubmit }) => {

  const revenuesOptions = [
    { option: 'Less than $1,000,000' },
    { option: '$1,000,000 to $5,000,000' },
    { option: '$5,000,000 to $15,000,000' },
    { option: 'More than $15,000,000' },
  ];

  const defaultFormData = {
    firstName: '',
    lastName: '',
    email: '',
    position: '',
    company: '',
    revenue: '',
  }
  const defaultErrorData = {
    firstName: false,
    lastName: false,
    email: false,
    position: false,
    company: false,
    revenue: false,
  }
  const [formData, setFormData] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState(defaultErrorData);

  const handleInputChange = (name, value) => {
    let newFormData = { ...formData };
    newFormData[name] = value;
    setFormData(newFormData);
  }

  const submitForm = () => {
    if (isValidForm()) {
      // setMessageSent(true);
      let payload = {
        event: 'formSubmit',
        type: zapiers.ppcForm.type,
        page: page,
        ...formData
      };

      // makeZapierRequest(payload);
      onSubmit(payload);
      cleanForm();
    }
  }

  const isValidForm = () => {
    let isValid = true;
    let newErrors = {};

    if (!formData?.firstName?.length) {
      isValid = false;
      newErrors.firstName = 'Please enter a First Name';
    } else {
      newErrors.firstName = false;
    }

    if (!formData?.lastName?.length) {
      isValid = false;
      newErrors.lastName = 'Please enter a Last Name';
    } else {
      newErrors.lastName = false;
    }

    if (!isEmailValid(formData?.email)) {
      isValid = false;
      newErrors.email = 'Please enter a valid Email';
    } else {
      newErrors.email = false;
    }

    if (!formData?.position?.length) {
      isValid = false;
      newErrors.position = 'Required Field';
    } else {
      newErrors.position = false;
    }

    if (!formData?.company?.length) {
      isValid = false;
      newErrors.company = 'Required Field';
    } else {
      newErrors.company = false;
    }

    setFormErrors(newErrors);
    // isValid = false;
    return isValid;
  }

  const cleanForm = () => {
    setFormData(defaultFormData);
    setFormErrors(defaultErrorData);
  }

  const isEmailValid = (email) => {
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  return <>

    <div className="row">
      <div className="col-12 col-lg-6">
        <FieldText
          name={'firstName'}
          value={formData['firstName']}
          label={'First Name'}
          placeholder={'Enter your first name'}
          required={true}
          inputStyles={{ fontWeight: '400' }}
          dark={false}
          invalid={formErrors['firstName']}
          onchange={(value) => handleInputChange('firstName', value)} />
      </div>
      <div className="col-12 col-lg-6">
        <FieldText
          name={'lastName'}
          value={formData['lastName']}
          label={'Last Name'}
          placeholder={'Enter your last name'}
          required={true}
          inputStyles={{ fontWeight: '400' }}
          dark={false}
          invalid={formErrors['lastName']}
          onchange={(value) => handleInputChange('lastName', value)} />
      </div>
      <div className='col-12'>
        <FieldText
          name={'email'}
          value={formData['email']}
          label={'Your Email'}
          placeholder={'Enter your email'}
          required={true}
          inputStyles={{ fontWeight: '400' }}
          dark={false}
          invalid={formErrors['email']}
          onchange={(value) => handleInputChange('email', value)} />
      </div>
      <div className="col-12 col-lg-6">
        <FieldText
          name={'position'}
          value={formData['position']}
          label={'Current Job Title'}
          placeholder={'CEO & Founder'}
          required={true}
          inputStyles={{ fontWeight: '400' }}
          dark={false}
          invalid={formErrors['position']}
          onchange={(value) => handleInputChange('position', value)} />
      </div>
      <div className="col-12 col-lg-6">
        <FieldText
          name={'company'}
          value={formData['company']}
          label={'Company Name'}
          placeholder={'Azarian Growth Agency'}
          required={true}
          inputStyles={{ fontWeight: '400' }}
          dark={false}
          invalid={formErrors['company']}
          onchange={(value) => handleInputChange('company', value)} />
      </div>
      <div className='col-12'>
        <FieldDropdown
          styles={{}}
          name={'revenue'}
          value={formData['revenue']}
          label={`Annual Revenue`}
          bigLabel={false}
          placeholder={'Revenue Options (Please select one)'}
          required={false}
          dark={false}
          options={revenuesOptions}
          invalid={formErrors['revenue']}
          onchange={(value) => handleInputChange('revenue', value)} />
      </div>
      <div className="col-12">
        <button type='button' className={s.submitButton} onClick={submitForm}>Register Now</button>
      </div>
    </div>
  </>
}

export default PpcHero;
